import { useState } from "react";
import { useEffect } from "react";
import Layout from "~@/components/Layout";
import { getMe } from "~@/utils/api";
import { useUser } from "~@/utils/hooks";
import { Issue } from "./Issues";
import { Empty } from "antd";
import ReactMoment from "react-moment";
const UserDetail = () => {
  const [data, setData] = useState<any>(null);
  const user = useUser();
  useEffect(() => {
    if (user?.token) {
      getMe(user.token).then((res) => {
        console.log(res);
        setData(res);
      });
    }
  }, [user]);
  if (!data) return <></>;
  return (
    <Layout>
      <section className="middleSection ptb_40">
        <ul className="shapesList">
          <li className="square_" />
          <li className="square_" />
          <li className="square_s" />
          <li className="round_" />
          <li className="round_" />
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-12">
              <div className="row">
                <div className="col-lg-9 col-md-8 col-12 pb-3">
                  <div className="userDetailCol">
                    <h3>User Detail</h3>
                    <ul className="userDetailList">
                      <li>
                        <i>
                          <img src="/images/ic_user-profile.svg" />
                        </i>{" "}
                        {data.first_name} {data.last_name}
                      </li>
                      <li>
                        <i>
                          <img src="/images/ic_email.svg" />
                        </i>{" "}
                        {data.email}
                      </li>
                      <li>
                        <i>
                          <img src="/images/ic_cake.svg" />
                        </i>{" "}
                        <ReactMoment format="DD-MMMM-YYYY">
                          {data.dob}
                        </ReactMoment>
                      </li>
                      <li>
                        <i>
                          <img src="/images/ic_work.svg" />
                        </i>{" "}
                        {data.occupation}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4 col-12 pb-3">
                  <div className="earnPointBox">
                    <img src="/images/giftBox.svg" className="giftBox" />
                    <h1>{data.points}</h1>
                    <label>Earn Points</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-10 offset-xl-1 col-12 pt-3">
              <div className="reportColPanel">
                <h3>Reported Issues</h3>
                {data.issues.length === 0 && <Empty />}
                {data.issues.map((issue) => (
                  <Issue {...issue} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default UserDetail;
