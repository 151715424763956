export enum helper_ACTIONS {
  SET_REPLY_TO = "SET_REPLY_TO",
}

export type Helper = {
  replyTo: string | null;
};
interface AbstractActionType<T extends helper_ACTIONS, U> {
  type: T;
  payload: U;
}

interface ISethelperType
  extends AbstractActionType<
    typeof helper_ACTIONS.SET_REPLY_TO,
    string | null
  > {}

export const setReplyTo = (replyTo: string | null): ISethelperType => {
  return {
    type: helper_ACTIONS.SET_REPLY_TO,
    payload: replyTo,
  };
};

export type helper_ACTION_TYPES = ISethelperType;
