import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { useEffect } from "react";
import _ from "lodash";

const Uploads: React.FC<{ onChange: any }> = ({ onChange: onFileUpdate }) => {
  const [fileList, setFileList] = React.useState([]);
  useEffect(() => {
    const files = fileList
      .filter((f: any) => f.status !== "uploading")
      .map((f) => _.get(f, "response.payload[0]"));
    onFileUpdate(files);
  }, [fileList]);
  return (
    <>
      <Upload
        action="https://api.thebugbunny.com/upload"
        listType="picture-card"
        fileList={fileList}
        name="files"
        multiple={true}
        showUploadList={{
          showPreviewIcon: false,
          showDownloadIcon: false,
        }}
        accept="image/*"
        //@ts-ignore
        onChange={({ fileList }) => setFileList(fileList)}
      >
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload</div>
        </div>
      </Upload>
    </>
  );
};

export default Uploads;
