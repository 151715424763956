import React from "react";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "~@/utils/hooks";
import { removeUser } from "~@/state/actions";
import { useDispatch } from "react-redux";
const Header = () => {
  const user = useUser();
  const dispatch = useDispatch();
  const history = useHistory();
  const logout = () => {
    dispatch(removeUser());
    history.push("/signup");
  };
  const isLogged = useMemo(() => (user ? true : false), [user]);
  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-md">
          <a className="navbar-brand" href="/">
            <img src="/images/logo.svg" alt="Bug Bunny" />
          </a>
          <button
            className="navbar-toggler navbar-toggler-right collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navb"
          >
            <span />
          </button>
          <div className="collapse navbar-collapse" id="navb">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/"} className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/issues"} className="nav-link">
                  Reported Issues
                </Link>
              </li>
              {isLogged ? (
                <>
                  <li className="nav-item">
                    <Link to={`/user/${user?.id}`} className="nav-link">
                      {user?.firstName} {user?.lastName}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link btnLink" onClick={logout}>
                      Log out
                    </a>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <Link to={"/login"} className="nav-link">
                      Login
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to={"/signup"} className="nav-link btnLink">
                      Sign Up
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
