import axios from "axios";
import Nprogress from "nprogress";
import { notification } from "antd";
const api = axios.create({
  baseURL: "https://api.thebugbunny.com",
  // baseURL: "http://localhost:5000",
  headers: {
    "Content-Type": "application/json",
  },
});
api.interceptors.request.use((v) => {
  Nprogress.start();
  return v;
});

api.interceptors.response.use(
  (r) => {
    Nprogress.done();
    return r;
  },
  (e) => {
    Nprogress.done();
    notification.open({
      message: e.response.data.payload,
      duration: 2,
    });
  }
);
export const signUp = async (user: {
  email: string;
  first_name: string;
  last_name: string;
  dob: string;
  password: string;
  occupation: string;
  username: string;
}) => {
  const { data } = await api.post("/signup", user);
  return data;
};

export const signIn = async (credentials: {
  emailOrUsername: string;
  password: string;
}) => {
  const { data } = await api.post("/signin", {
    email: credentials.emailOrUsername,
    password: credentials.password,
  });
  return data.payload;
};

export const createIssue = async (
  data: {
    attachments?: any[];
    url: string;
    summary: string;
    desc: string;
    metadata?: object;
  },
  token: string | null = null
) => {
  const { data: res } = await api.post("/issues", data, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });
  return res.payload;
};

export const fetchIssues = async (token = null) => {
  const { data } = await api.get("/issues", {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });
  return data.payload;
};

export const searchIssues = async (q: string) => {
  const { data } = await api.get("/issues/search", {
    params: {
      q,
    },
  });
  return data.payload;
};

export const fetchIssue = async (id, token = null) => {
  const { data } = await api.get(`/issues/${id}`, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });
  return data.payload;
};

export const fetchComments = async (issueId) => {
  const { data } = await api.get(`/issues/${issueId}/comment`);
  return data.payload;
};

export const postComment = async (
  input: {
    issueId: string;
    content: string;
  },
  token: string,
  replyTo = null
) => {
  const { data } = await api.post(
    `/issues/${input.issueId}/comment`,
    {
      content: input.content,
      reply_to: replyTo,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data.payload;
};

export const likePost = async (postId, token: string) => {
  const { data } = await api.post(`issues/${postId}/like`, null, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });
  return data.payload;
};

export const dislikePost = async (postId, token: string) => {
  const { data } = await api.post(`issues/${postId}/dislike`, null, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : undefined,
  });
  return data.payload;
};

export const getMe = async (token: string) => {
  const { data } = await api.get("profile/me", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
  return data.payload;
};

export const getResetTokenInfo = async (token: string) => {
  const { data } = await api.get(`/reset/${token}`);
  return data;
};

export const resetPassword = async ({ token, newPassword }) => {
  const { data } = await api.post(`/reset/`, {
    token,
    newPass: newPassword,
  });
};

export const forgotPassword = async (email: string) => {
  const { data } = await api.post("/forgot", {
    email,
  });
  return data.payload;
};
