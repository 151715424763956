import Layout from "~@/components/Layout";
import { Formik, Form, ErrorMessage } from "formik";
import { useHistory, Link, useParams } from "react-router-dom";
import * as yup from "yup";
import { useEffect } from "react";
import { getResetTokenInfo, resetPassword } from "~@/utils/api";
import { useState } from "react";
const Reset = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const [tokenError, setTokenError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const onSubmit = async (v) => {
    await resetPassword({
      token,
      newPassword: v.password,
    });
    history.push("/login");
  };
  const validationSchema = yup.object().shape({
    password: yup.string().required("Required"),
    rePassword: yup.string().required("Required"),
  });

  useEffect(() => {
    if (token) {
      getResetTokenInfo(token).then((data) => {
        if (data.isError) {
          setTokenError(data.payload);
        }
        setIsLoading(false);
      });
    }
  }, [token]);

  return (
    <Layout>
      {tokenError.length > 0 && (
        <>
          <section className="middleSection ptb_40">
            <ul className="shapesList">
              <li className="square_" />
              <li className="square_" />
              <li className="square_s" />
              <li className="round_" />
              <li className="round_" />
            </ul>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-10 offset-lg-3 offset-md-1 col-12">
                  <h1 className="text-center">Oops!!!</h1>
                  <h1 className="text-center field_Css">{tokenError}</h1>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {!isLoading && tokenError.length === 0 && (
        <section className="middleSection ptb_40">
          <ul className="shapesList">
            <li className="square_" />
            <li className="square_" />
            <li className="square_s" />
            <li className="round_" />
            <li className="round_" />
          </ul>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 offset-lg-3 offset-md-1 col-12">
                <h1 className="text-center">Reset Password</h1>
                <Formik
                  initialValues={{
                    password: "",
                    rePassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    values,
                    isSubmitting,
                  }) => (
                    <Form className="formWrap" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              New Password <span className="important">*</span>
                            </label>
                            <input
                              type="password"
                              name="password"
                              placeholder="Enter New password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="password"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Confirm Password
                              <span className="important">*</span>
                            </label>
                            <input
                              type="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="rePassword"
                              placeholder="Enter password"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="rePassword"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        {/* <div className="col-12">
                        <div className="form-group text-right mb-3">
                          <a href="#" className="forget_pw">
                            Forget Password?
                          </a>
                        </div>
                      </div> */}
                        <div className="col-md-12 col-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn_1"
                              disabled={isSubmitting}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="col-12">
                          <p className="dontHaveAc mb-0">
                            Don't have account?{" "}
                            <Link to="/Signup">Create a new account</Link>
                          </p>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      )}
    </Layout>
  );
};

export default Reset;
