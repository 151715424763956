import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Layout from "~@/components/Layout";
import _ from "lodash";
import { fetchIssues, searchIssues } from "~@/utils/api";
import { Empty } from "antd";
import ReadMore from "read-more-react";
export const Issue = (props) => {
  const history = useHistory();
  return (
    <div
      className="reportColPanel searchResult"
      onClick={() => history.push(`/issue/${props.id}`)}
    >
      <div className="reportCol">
        <div className="reportInfoBox">
          <ul className="reportList">
            <li>
              <label>URL : </label>
              <span>{props.url}</span>
            </li>
            <li>
              <label>Summary : </label>
              <span>{props.summary}</span>
            </li>
            <li>
              <label>Detail: </label>
              <ReadMore
                min={100}
                ideal={200}
                text={props.desc}
                readMoreText={"Read more..."}
              />
            </li>
          </ul>
          <ul className="reportBtnList">
            <li>
              <button type="button" className="btnL">
                <img src="/images/like.svg" /> {props.likes}
              </button>
            </li>
            <li>
              <button type="button" className="btnL">
                <img src="/images/deslike.svg" /> {props.dislikes}
              </button>
            </li>
            <li>
              <button type="button" className="btnL">
                <img src="/images/comment.svg" /> {props.comments}
              </button>
            </li>
          </ul>
        </div>
        <div className="reportImgBox">
          {props.status === "resovled" ? (
            <div className="reportResult_ resolved_">
              <img src="/images/checked.svg" /> Resovled
            </div>
          ) : (
            <div className="reportResult_ unresolved_">
              <img src="/images/cancel.svg" /> Unresolved
            </div>
          )}
          <ul className="reportImgList">
            {_.slice(props.attachments, 0, 4).map((attach: any, i) => (
              <li key={i}>
                <img src={attach.url} />
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/*reportCol*/}
    </div>
  );
};
const Issues = () => {
  const [issues, setIssues] = useState([]);
  const [q, setQ] = useState("");
  const onSearch = async () => {
    const issues = await searchIssues(q);
    setIssues(issues);
  };
  const onChange = (e) => setQ(e.target.value);
  useEffect(() => {
    fetchIssues().then((res) => setIssues(res));
  }, []);
  return (
    <Layout>
      <section className="middleSection ptb_40">
        <ul className="shapesList">
          <li className="square_" />
          <li className="square_" />
          <li className="square_s" />
          <li className="round_" />
          <li className="round_" />
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-12 pt-3">
              <div className="searchWrapper">
                <h1>Search a website to see reported issues</h1>
                <div className="searchbox">
                  <input
                    type="text"
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        onSearch();
                      }
                    }}
                    className="searchText"
                    value={q}
                    onChange={onChange}
                    placeholder="www.example.com"
                  />
                  <button className="searchBTN" onClick={onSearch}>
                    <img src="/images/searchicon.png" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {issues.length === 0 && <Empty />}
          {issues.map((issue, i) => (
            <Issue key={i} {...issue} />
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default Issues;
