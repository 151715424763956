import { useSelector } from "react-redux";
import { AppState } from "~@/state";

export const useUser = () => {
  return useSelector((state: AppState) => state.user);
};

export const useHelper = () => {
  return useSelector((state: AppState) => state.helper);
};
