import { createStore, combineReducers } from "redux";
import userReducer from "./user/reducer";
import helperReducer from "./helper/reducer";

import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  user: userReducer,
  helper: helperReducer,
});

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, composeWithDevTools());
export const persistor = persistStore(<any>store);
//@ts-ignore
// export type AppState = StateFromReducersMapObject<typeof reducerObject>; dedicapted
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export default store;
