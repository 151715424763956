import Home from "~@/pages/Home";
import SignUp from "~@/pages/Signup";
import SignIn from "~@/pages/SignIn";
import Report from "~@/pages/Report";
import Reset from "~@/pages/Reset";

import Issues from "~@/pages/Issues";
import IssueDetail from "~@/pages/IssueDetail";
import UserDetail from "~@/pages/UserDetail";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import store, { persistor } from "~@/state";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Forget from "~@/pages/Forget";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/signup" component={SignUp} />
            <Route path="/login" component={SignIn} />
            <Route path="/report" component={Report} />
            <Route path="/issues" component={Issues} />
            <Route path="/issue/:id" component={IssueDetail} />
            <Route path="/user/:id" component={UserDetail} />
            <Route path="/reset/:token" component={Reset} />
            <Route path="/forget/" component={Forget} />
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
