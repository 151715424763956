import Layout from "~@/components/Layout";
import { Link } from "react-router-dom";
const Home = () => {
  return (
    <Layout>
      <section className="middleSection ptb_40">
        <ul className="shapesList">
          <li className="square_" />
          <li className="square_" />
          <li className="square_s" />
          <li className="round_" />
          <li className="round_" />
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-10 offset-xl-3 offset-md-1 col-12 text-center">
              <h2>
                Create an account Report an issue Earn carrots Redeem for
                courses
              </h2>
              <Link to="/report" className="btn_1">
                Report An Issue
              </Link>
            </div>
          </div>
          <div className="row step_Row">
            <div className="col-md-4 col-12">
              <div className="step_col">
                <div className="ic_step">
                  <img src="/images/ic_bug.png" />
                </div>
                <div className="step_title">01. Find a Bug</div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="step_col">
                <div className="ic_step">
                  <img src="/images/ic_report.png" />
                </div>
                <div className="step_title">
                  02. Full out Report an Issue form
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="step_col">
                <div className="ic_step">
                  <img src="/images/ic_coins.png" />
                </div>
                <div className="step_title">
                  03. Submit the form &amp; earn points
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Home;
