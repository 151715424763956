import { useEffect } from "react";
import Layout from "~@/components/Layout";
import { useHistory, useParams } from "react-router-dom";
import { dislikePost, fetchComments, fetchIssue, likePost } from "~@/utils/api";
import { useState } from "react";
import { Formik } from "formik";
import { postComment } from "~@/utils/api";
import { useUser, useHelper } from "~@/utils/hooks";
import { useMemo } from "react";
import { setReplyTo } from "~@/state/actions";
import { useDispatch } from "react-redux";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import Moment from "react-moment";

const Preview = ({ detail }) => {
  useEffect(() => {
    //@ts-ignore
    $(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 4,
          loop: false,
        },
      },
    });
  }, []);
  return (
    <>
      {detail && (
        <>
          <section className="middleSection ptb_40">
            <ul className="shapesList">
              <li className="square_" />
              <li className="square_" />
              <li className="square_s" />
              <li className="round_" />
              <li className="round_" />
            </ul>
            <div className="container">
              <div className="row">
                <div className="col-xl-10 offset-xl-1 col-12 pt-lg-3">
                  <div className="reportColPanel issueDetails">
                    <div className="reportCol">
                      <div className="reportInfoBox pr-0">
                        <h3>Issues Detail</h3>

                        {/* {detail.status === "resovled" ? (
                          <div className="reportResult2 resolved_">
                            <img src="/images/checked.svg" /> Resovled
                          </div>
                        ) : (
                          <div className="reportResult2 unresolved_">
                            <img src="/images/cancel.svg" /> Unresolved
                          </div>
                        )} */}
                        <ul className="reportList">
                          <li>
                            <label>URL : </label>
                            <span>{detail!.url}</span>
                          </li>
                          <li>
                            <label>Summary : </label>
                            <span>{detail!.summary}</span>
                          </li>
                        </ul>
                        <div className="issueDetailsBox">
                          <h6>Detail</h6>
                          <p>{detail!.description}</p>
                        </div>
                        <div className="attachedFiles pt-3">
                          <h6>Attachment</h6>
                          <div className="owl-carousel">
                            {detail!.files.map((attachment, i) => (
                              <div key={i}>
                                <img src={attachment.url} />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Preview;
