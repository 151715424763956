import React from "react";

import Layout from "~@/components/Layout";
import { Formik, Form, ErrorMessage } from "formik";
import { signUp } from "~@/utils/api";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
const SignUp = () => {
  const history = useHistory();
  const validationSchema = yup.object().shape({
    email: yup.string().email("Please input valid email").required("required"),
    username: yup
      .string()
      .min(5, "username must be greater than 5 characters")
      .required("required"),
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    dob: yup.date().required("required"),
    occupation: yup.string(),
    password: yup
      .string()
      .min(6, "password must be greater than 6 characters")
      .required("required"),
    rePassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });
  return (
    <>
      <Layout>
        <section className="middleSection ptb_40">
          <ul className="shapesList">
            <li className="square_" />
            <li className="square_" />
            <li className="square_s" />
            <li className="round_" />
            <li className="round_" />
          </ul>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 offset-lg-3 offset-md-1 col-12">
                <h1 className="text-center">Sign Up</h1>

                <Formik
                  initialValues={{
                    email: "",
                    username: "",
                    firstName: "",
                    lastName: "",
                    dob: "",
                    occupation: "",
                    password: "",
                    rePassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (v) => {
                    await signUp({
                      dob: v.dob,
                      email: v.email,
                      first_name: v.firstName,
                      last_name: v.lastName,
                      occupation: v.occupation,
                      password: v.password,
                      username: v.username,
                    });
                    history.push("/login");
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    handleBlur,
                    isSubmitting,
                  }) => (
                    <Form className="formWrap" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Email <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="example@gmail.com"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="email"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                          <div className="form-group">
                            <label className="label">
                              Username <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="example@gmail.com"
                              name="username"
                              value={values.username}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="username"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label className="label">
                              First Name <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              value={values.firstName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter first name"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="firstName"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label className="label">
                              Last Name <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              value={values.lastName}
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter last name"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="lastName"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label className="label">Birthday</label>
                            <input
                              type="date"
                              value={values.dob}
                              name="dob"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              // placeholder="Select date"
                              className="field_Css "
                            />
                            <ErrorMessage
                              name="dob"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="form-group">
                            <label className="label">Occupation</label>
                            <input
                              type="text"
                              placeholder="Your occupation"
                              className="field_Css"
                              name="occupation"
                              value={values.occupation}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="occupation"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Password <span className="important">*</span>
                            </label>
                            <input
                              type="password"
                              placeholder="Enter password"
                              className="field_Css"
                              name="password"
                              value={values.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <ErrorMessage
                              name="password"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Confirm Password{" "}
                              <span className="important">*</span>
                            </label>
                            <input
                              type="password"
                              value={values.rePassword}
                              onChange={handleChange}
                              name="rePassword"
                              onBlur={handleBlur}
                              placeholder="Confirm password"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="rePassword"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>

                        <div className="col-md-12 col-12">
                          <div className="form-group">
                            <button
                              type="submit"
                              className="btn_1"
                              disabled={isSubmitting}
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default SignUp;
