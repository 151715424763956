import { USER_ACTION_TYPES, USER_ACTIONS, USER } from "./actions";

const userReducer = (user: USER | null = null, action: USER_ACTION_TYPES) => {
  switch (action.type) {
    case USER_ACTIONS.SET_USER: {
      if (!user) {
        return {
          ...action.payload,
        };
      } else {
        return {
          ...user,
          ...action.payload,
        };
      }
      break;
    }
    case USER_ACTIONS.REMOVE_USER: {
      return null;
    }
    default: {
      return user;
    }
  }
};

export default userReducer;
