import { useMemo } from "react";
import Layout from "~@/components/Layout";
import { useUser } from "~@/utils/hooks";
import Upload from "~@/components/Upload";
import { Formik, Form, ErrorMessage } from "formik";
import { createIssue } from "~@/utils/api";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import * as yup from "yup";
import Preview from "~@/components/Preview";
import { useState } from "react";
import { useEffect } from "react";

const GuestSignUpModal = ({ visible, onClose, onSubmit }) => {
  const validationSchema = yup.object().shape({
    first_name: yup.string().required("required"),
    last_name: yup.string().required("required"),
    email: yup.string().email("Must be a valid email").required("required"),
  });
  return (
    <Modal
      visible={visible}
      title="Continue As Guest"
      footer={null}
      onCancel={onClose}
      closable={true}
    >
      <Formik
        initialValues={{
          first_name: "",
          last_name: "",
          email: "",
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label className="label">
                    First Name<span className="important">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="field_Css"
                  />
                  <ErrorMessage
                    name="first_name"
                    component={"div"}
                    className="error-message"
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="label">
                    Last Name <span className="important">*</span>
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Last Name"
                    className="field_Css"
                  />
                  <ErrorMessage
                    name="last_name"
                    component={"div"}
                    className="error-message"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label className="label">
                    Email <span className="important">*</span>
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Email"
                    className="field_Css"
                  />
                  <ErrorMessage
                    name="email"
                    component={"div"}
                    className="error-message"
                  />
                </div>
              </div>
              <div className="col-md-12 col-12">
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn_1"
                    disabled={isSubmitting}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const PreviewModal = ({ visible, onClose, detail }) => {
  return (
    <Modal
      width={"80%"}
      visible={visible}
      title="Preview"
      footer={null}
      onCancel={onClose}
      closable={true}
    >
      <Preview detail={detail} />
    </Modal>
  );
};

const ReportIssue = () => {
  const [type, setType] = useState<string | null>(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const user = useUser();
  const history = useHistory();

  const isLogged = useMemo(() => (user ? true : false), [user]);
  const [isShowGuestModal, setIsShowGuestModal] = useState(false);
  const validationSchema = yup.object().shape({
    url: yup.string().required("required"),
    summary: yup.string().required("required"),
    description: yup.string().required("required"),
    files: yup.array(),
  });
  const onSubmit = async (v) => {
    const res = await createIssue(
      {
        desc: v.description,
        summary: v.summary,
        url: v.url,
        attachments: v.files,
        metadata: v.metadata ? v.metadata : {},
      },
      user ? user.token : null
    );
    history.push(`/issue/${res.id}`);
  };

  const onCreateAccount = () => {
    history.push("/signup");
  };
  const onSubmitAsGuest = (values, metadata) => {
    return onSubmit({
      ...values,
      metadata,
    });
  };
  useEffect(() => {
    if (user) {
      setType("USER");
    }
  }, [user]);
  return (
    <Layout>
      <section className="middleSection ptb_40">
        <ul className="shapesList">
          <li className="square_" />
          <li className="square_" />
          <li className="square_s" />
          <li className="round_" />
          <li className="round_" />
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10 offset-lg-3 offset-md-1 col-12">
              <h1 className="text-center">Found a bug? Report an Issue</h1>
              {type === null && (
                <div className="row">
                  <div className="col-md-6 col-6">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn_1"
                        onClick={onCreateAccount}
                      >
                        Create An Account
                      </button>
                    </div>
                  </div>
                  <div className="col-md-6 col-6">
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn_1"
                        onClick={() => setType("GUEST")}
                      >
                        Continue as Guest
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {type === "USER" && (
                <Formik
                  initialValues={{
                    url: "",
                    summary: "",
                    description: "",
                    files: [],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    setFieldValue,
                    isSubmitting,
                    setTouched,
                    handleChange,
                    setErrors,
                    validateForm,
                    handleBlur,
                    values,
                    handleSubmit,
                  }) => (
                    <Form className="formWrap" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Website / URL <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="www.example.com"
                              name="url"
                              value={values.url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="url"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Summary <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              name="summary"
                              value={values.summary}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter summary"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="summary"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Description <span className="important">*</span>
                            </label>
                            <textarea
                              className="textarea_Css"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter your description"
                            />
                            <ErrorMessage
                              name="description"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">File Upload</label>
                            <div className="fileUploadCol">
                              <Upload
                                onChange={(files) => {
                                  setFieldValue("files", files);
                                }}
                              />
                            </div>
                            <ErrorMessage
                              name="files"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        {isLogged && (
                          <>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button
                                  className="btn_1"
                                  type="button"
                                  onClick={() => setShowPreviewModal(true)}
                                >
                                  Preview
                                </button>
                              </div>
                            </div>
                            <div className="col-md-6 col-6">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn_1"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <GuestSignUpModal
                        visible={isShowGuestModal}
                        onClose={() => setIsShowGuestModal(false)}
                        onSubmit={(v) => onSubmitAsGuest(values, v)}
                      />
                      <PreviewModal
                        detail={values}
                        visible={showPreviewModal}
                        onClose={() => setShowPreviewModal(false)}
                      />
                    </Form>
                  )}
                </Formik>
              )}
              {type === "GUEST" && (
                <Formik
                  initialValues={{
                    url: "",
                    summary: "",
                    description: "",
                    files: [],
                  }}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    setFieldValue,
                    isSubmitting,
                    setTouched,
                    handleChange,
                    setErrors,
                    validateForm,
                    handleBlur,
                    values,
                    handleSubmit,
                  }) => (
                    <Form className="formWrap" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Website / URL <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="www.example.com"
                              name="url"
                              value={values.url}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="url"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Summary <span className="important">*</span>
                            </label>
                            <input
                              type="text"
                              name="summary"
                              value={values.summary}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter summary"
                              className="field_Css"
                            />
                            <ErrorMessage
                              name="summary"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">
                              Description <span className="important">*</span>
                            </label>
                            <textarea
                              className="textarea_Css"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter your description"
                            />
                            <ErrorMessage
                              name="description"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label">File Upload</label>
                            <div className="fileUploadCol">
                              <Upload
                                onChange={(files) => {
                                  setFieldValue("files", files);
                                }}
                              />
                            </div>
                            <ErrorMessage
                              name="files"
                              component={"div"}
                              className="error-message"
                            />
                          </div>
                        </div>
                        {isLogged ? (
                          <>
                            <div className="col-md-12 col-12">
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn_1"
                                  disabled={isSubmitting}
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button
                                  className="btn_1"
                                  type="button"
                                  onClick={() => setShowPreviewModal(true)}
                                >
                                  Preview
                                </button>
                              </div>
                            </div>
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <button
                                  className="btn_1  mt-m-0"
                                  type="button"
                                  onClick={() =>
                                    validateForm(values).then((errors) => {
                                      if (Object.keys(errors).length) {
                                        setErrors(errors);
                                        setTouched({
                                          description: true,
                                          files: [],
                                          summary: true,
                                          url: true,
                                        });
                                      } else {
                                        setIsShowGuestModal(true);
                                      }
                                    })
                                  }
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <GuestSignUpModal
                        visible={isShowGuestModal}
                        onClose={() => setIsShowGuestModal(false)}
                        onSubmit={(v) => onSubmitAsGuest(values, v)}
                      />
                      <PreviewModal
                        detail={values}
                        visible={showPreviewModal}
                        onClose={() => setShowPreviewModal(false)}
                      />
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ReportIssue;
