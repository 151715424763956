export enum USER_ACTIONS {
  SET_USER = "SET_USER",
  REMOVE_USER = "REMOVE_USER",
}

export type USER = {
  token: string;
  email: string;
  id: string;
  firstName: string;
  lastName: string;
};

interface AbstractActionType<T extends USER_ACTIONS, U> {
  type: T;
  payload: U;
}

interface ISetUserType
  extends AbstractActionType<typeof USER_ACTIONS.SET_USER, USER> {}

export const setUser = (user: USER): ISetUserType => {
  return {
    type: USER_ACTIONS.SET_USER,
    payload: user,
  };
};

interface IRemoveUserType
  extends AbstractActionType<typeof USER_ACTIONS.REMOVE_USER, null> {}

export const removeUser = (): IRemoveUserType => {
  return {
    type: USER_ACTIONS.REMOVE_USER,
    payload: null,
  };
};

export type USER_ACTION_TYPES = ISetUserType | IRemoveUserType;
