import Layout from "~@/components/Layout";
import { Formik, Form, ErrorMessage } from "formik";
import { signIn } from "~@/utils/api";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "~@/state/actions";
import { AppState } from "~@/state";
import { useUser } from "~@/utils/hooks";
import { useEffect } from "react";

import * as yup from "yup";
const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useUser();
  const onSubmit = async (v) => {
    const data = await signIn({
      emailOrUsername: v.emailOrUsername,
      password: v.password,
    });
    dispatch(
      setUser({
        id: data.id,
        email: data.email,
        firstName: data.first_name,
        lastName: data.last_name,
        token: data.token,
      })
    );
    history.push("/user/1");
  };
  const validationSchema = yup.object().shape({
    emailOrUsername: yup.string().required("Required"),
    password: yup.string().required("Required"),
  });

  useEffect(() => {
    if (user) {
      history.push(`/user/${user.id}`);
    }
  }, [user]);
  return (
    <Layout>
      <section className="middleSection ptb_40">
        <ul className="shapesList">
          <li className="square_" />
          <li className="square_" />
          <li className="square_s" />
          <li className="round_" />
          <li className="round_" />
        </ul>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10 offset-lg-3 offset-md-1 col-12">
              <h1 className="text-center">Login</h1>
              <Formik
                initialValues={{
                  emailOrUsername: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  values,
                  isSubmitting,
                }) => (
                  <Form className="formWrap" onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group">
                          <label className="label">
                            Email <span className="important">*</span>
                          </label>
                          <input
                            type="text"
                            name="emailOrUsername"
                            placeholder="example@gmail.com"
                            value={values.emailOrUsername}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="field_Css"
                          />
                          <ErrorMessage
                            name="emailOrUsername"
                            component={"div"}
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label className="label">
                            Password <span className="important">*</span>
                          </label>
                          <input
                            type="password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="password"
                            placeholder="Enter password"
                            className="field_Css"
                          />
                          <ErrorMessage
                            name="password"
                            component={"div"}
                            className="error-message"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group text-right mb-3">
                          <Link to="/forget" className="forget_pw">
                            Forget Password?
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-12 col-12">
                        <div className="form-group">
                          <button
                            type="submit"
                            className="btn_1"
                            disabled={isSubmitting}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                      <div className="col-12">
                        <p className="dontHaveAc mb-0">
                          Don't have account?{" "}
                          <Link to="/Signup">Create a new account</Link>
                        </p>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default SignIn;
