import { useEffect } from "react";
import Layout from "~@/components/Layout";
import { useHistory, useParams } from "react-router-dom";
import { dislikePost, fetchComments, fetchIssue, likePost } from "~@/utils/api";
import { useState } from "react";
import { Formik } from "formik";
import { postComment } from "~@/utils/api";
import { useUser, useHelper } from "~@/utils/hooks";
import { useMemo } from "react";
import { setReplyTo } from "~@/state/actions";
import { useDispatch } from "react-redux";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import Moment from "react-moment";

const Comment = (props) => {
  const [user, helper] = [useUser(), useHelper()];
  const dispatch = useDispatch();
  const onReply = async (v) => {
    await postComment(
      {
        content: v.value,
        issueId: props.issue_id,
      },
      user!.token,
      props.replyTo || props.id
    );
    props.loadComments();
    toggleReplyBox();
  };
  const isShowReplyBox = useMemo(() => {
    if (helper.replyTo === props.id) return true;
    return false;
  }, [helper, props]);
  const toggleReplyBox = () => {
    if (isShowReplyBox) {
      return dispatch(setReplyTo(null));
    } else {
      return dispatch(setReplyTo(props.id));
    }
  };
  return (
    <>
      <div className="fbCommentRow">
        <div className="profilepic">
          <span>
            <img src="/images/profilePic.png" />
          </span>
        </div>
        <div className="commentRiht">
          <div className="fbTitle">
            {props.user.first_name} {props.user.last_name}{" "}
            <span>
              <Moment fromNow>{props.created_at}</Moment>
            </span>
          </div>
          <p>{props.content}</p>
          <ul className="reportBtnList">
            <li>
              <button type="button" className="btnL">
                <img src="/images/like.svg" /> {props.likes}
              </button>
            </li>
            <li>
              <button type="button" className="btnL">
                <img src="/images/deslike.svg" /> {props.dislikes}
              </button>
            </li>
            <li>
              <button type="button" className="btnL" onClick={toggleReplyBox}>
                Reply
              </button>
            </li>
          </ul>
          {isShowReplyBox && (
            <>
              <div
                style={{
                  marginBottom: "20px",
                }}
              ></div>
              <AddComent onSubmit={onReply} />
            </>
          )}
          {props.replies && (
            <>
              <div
                style={{
                  marginBottom: "20px",
                }}
              ></div>
              {props.replies.map((reply, i) => (
                <Comment
                  key={i}
                  {...reply}
                  replyTo={props.id}
                  loadComments={props.loadComments}
                />
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};
const AddComent: React.FC<{ onSubmit }> = ({ onSubmit }) => {
  return (
    <Formik
      initialValues={{
        value: "",
      }}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleSubmit }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <textarea
                className="textarea_Css"
                name="value"
                placeholder="Enter your description"
                value={values.value}
                onChange={handleChange}
              ></textarea>
            </div>
            <button className="btn_comment" type="submit">
              Add Comment
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};
const IssueDetail = () => {
  const [detail, setDetail] = useState<any>(null);
  const params = useParams();
  const dispatch = useDispatch();
  const [user, helper] = [useUser(), useHelper()];
  const history = useHistory();
  const onToggleLikePost = async () => {
    if (!user) {
      return history.push("/signup");
    }
    const res = await likePost(detail.id, user.token);
    setDetail(res);
  };

  const onToggleDislikePost = async () => {
    if (!user) {
      return history.push("/signup");
    }
    const res = await dislikePost(detail.id, user.token);
    setDetail(res);
  };

  const isShowCommentBox = useMemo(() => {
    if (!detail) {
      return false;
    }
    if (helper.replyTo === detail.id) {
      return true;
    }
    return false;
  }, [helper, detail]);

  const toggleCommentBox = () => {
    if (isShowCommentBox) {
      dispatch(setReplyTo(null));
    } else {
      dispatch(setReplyTo(detail.id));
    }
  };
  const [comments, setcomments] = useState([]);
  const onPostComment = async (v) => {
    await postComment(
      {
        issueId: params["id"],
        content: v.value,
      },
      user!.token
    );
    loadComments();
    toggleCommentBox();
  };
  const loadComments = () => {
    fetchComments(params["id"]).then((res) => {
      setcomments(res);
    });
  };
  useEffect(() => {
    if (params["id"]) {
      fetchIssue(params["id"], user?.token as any).then((res) => {
        setDetail(res);
        //@ts-ignore
        $(".owl-carousel").owlCarousel({
          loop: true,
          margin: 10,
          nav: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 4,
              loop: false,
            },
          },
        });
      });
      loadComments();
    }
  }, [params]);

  return (
    <Layout>
      {detail && (
        <>
          <section className="middleSection ptb_40">
            <ul className="shapesList">
              <li className="square_" />
              <li className="square_" />
              <li className="square_s" />
              <li className="round_" />
              <li className="round_" />
            </ul>
            <div className="container">
              <div className="row">
                <div className="col-xl-10 offset-xl-1 col-12 pt-lg-3">
                  <div className="reportColPanel issueDetails">
                    <div className="reportCol">
                      <div className="reportInfoBox pr-0">
                        <h3>Issues Detail</h3>

                        {detail.status === "resovled" ? (
                          <div className="reportResult2 resolved_">
                            <img src="/images/checked.svg" /> Resovled
                          </div>
                        ) : (
                          <div className="reportResult2 unresolved_">
                            <img src="/images/cancel.svg" /> Unresolved
                          </div>
                        )}
                        <ul className="reportList">
                          <li>
                            <label>URL : </label>
                            <span>{detail!.url}</span>
                          </li>
                          <li>
                            <label>Summary : </label>
                            <span>{detail!.summary}</span>
                          </li>
                        </ul>
                        <div className="issueDetailsBox">
                          <h6>Detail</h6>
                          <p>{detail!.desc}</p>
                        </div>
                        <div className="attachedFiles pt-3">
                          <h6>Attachment</h6>
                          <div className="owl-carousel">
                            {detail!.attachments.map((attachment, i) => (
                              <div key={i}>
                                <img src={attachment.url} />
                              </div>
                            ))}
                          </div>
                        </div>
                        <ul className="reportBtnList">
                          <li>
                            <button
                              type="button"
                              className="btnL"
                              onClick={onToggleLikePost}
                            >
                              <LikeOutlined
                                style={{
                                  fontSize: 22,
                                  marginRight: 2,
                                  color: detail.userMeta
                                    ? detail.userMeta.likes
                                      ? "#b666d2"
                                      : "black"
                                    : "black",
                                }}
                              />
                              {detail.likes}
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="btnL"
                              onClick={onToggleDislikePost}
                            >
                              <DislikeOutlined
                                style={{
                                  fontSize: 22,
                                  marginRight: 2,
                                  color: detail.userMeta
                                    ? detail.userMeta.dislikes
                                      ? "#b666d2"
                                      : "black"
                                    : "black",
                                }}
                              />
                              {detail.dislikes}
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              className="btnL"
                              onClick={toggleCommentBox}
                            >
                              <img src="/images/comment.svg" />{" "}
                              {detail.comments}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="commentRow">
                      {isShowCommentBox && (
                        <AddComent onSubmit={onPostComment} />
                      )}
                      <h6>Comments</h6>

                      {comments.map((comment, i) => (
                        <Comment
                          loadComments={loadComments}
                          {...comment}
                          key={i}
                        />
                      ))}
                    </div>
                    {/*reportCol*/}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default IssueDetail;
