import { Helper, helper_ACTION_TYPES, helper_ACTIONS } from "./actions";

const helperReducer = (
  helper: Helper = {
    replyTo: null,
  },
  action: helper_ACTION_TYPES
) => {
  switch (action.type) {
    case helper_ACTIONS.SET_REPLY_TO: {
      return {
        ...helper,
        replyTo: action.payload,
      };
      break;
    }

    default: {
      return helper;
    }
  }
};

export default helperReducer;
